/**
 * @param {String} text
 * @returns {Document}
 */
export function parseTextToHtml(text) {
    return new DOMParser().parseFromString(text, 'text/html');
}

export function isInsideIframe() {
    return window.self !== window.top;
}
