for (const form of document.querySelectorAll('[data-financial-lease-calculator]')) {
    let fetchController;
    const targets = document.querySelectorAll(form.dataset.target);

    form.addEventListener('input', (e) => {
        e.preventDefault();

        if (fetchController) {
            fetchController.abort('Already running a calculation.');
        }

        fetchController = new AbortController();

        const input = e.target;
        if (input.hasAttribute('max')) {
            let price = input.value.replaceAll(',', '.');
            price = price.replaceAll(' ', '');
            price = price.replaceAll(/[,.](?!\d{1,2}$)/gi, '');

            if (Number.parseFloat(price) > Number.parseFloat(input.max)) {
                input.value = new Intl.NumberFormat('nl-NL', { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(input.max);
            }
        }

        // Prevent unnecessary calculations
        if (form.querySelector('input[name=grossInvestment]').value === '') {
            return;
        }

        for (const formFieldWithError of form.querySelectorAll('.form-field.error')) {
            formFieldWithError.classList.remove('error');
        }

        // Remove all previous error messages
        for (const formErrorContainer of form.querySelectorAll('.form-field-error-container')) {
            formErrorContainer.remove();
        }

        fetch('/financial-lease/calculate', {
            method: 'POST',
            body: new FormData(form),
            signal: fetchController.signal,
        })
            .then((response) => {
                if (!response.ok) {
                    if (response.headers.get('Content-Type').includes('application/json')) {
                        return Promise.reject(response);
                    }

                    throw new Error('Failed to calculate due to an invalid response from the server');
                }

                return response.json();
            })
            .then((json) => {
                for (const target of targets) {
                    if (target.tagName === 'INPUT') {
                        target.value = json.monthlyPayment;
                    } else {
                        target.innerText = json.monthlyPayment;
                    }
                }
            })
            .catch(async (exception) => {
                if (typeof exception === 'string' && exception.includes('Aborted')) {
                    return;
                }

                if (!(exception instanceof Response)) {
                    console.error(exception);
                    return;
                }

                const errors = await exception.json();

                if (!Object.hasOwn(errors, 'formErrors')) {
                    console.error('Received a response but did not match expectations.', errors);
                }

                for (const fieldName in errors['formErrors']) {
                    const messages = errors['formErrors'][fieldName];
                    const formField = form.querySelector(`[name="${fieldName}"]`);
                    formField.classList.add('error');

                    const formFieldErrorContainer = document.createElement('ul');
                    formFieldErrorContainer.classList.add('form-field-error-container', 'col-span-2', 'text-right');

                    for (const message of messages) {
                        const formFieldError = document.createElement('li');
                        formFieldError.classList.add('text-sm', 'text-red-600', 'dark:text-red-500');
                        formFieldError.innerText = message;
                        formFieldErrorContainer.appendChild(formFieldError);
                    }

                    formField.closest('.form-group').append(formFieldErrorContainer);
                }
            });
    });

    form.dispatchEvent(new Event('input'));

    const submitButton = form.querySelector('[type=submit]');
    if (submitButton !== null) {
        submitButton.hidden = true;
        submitButton.classList.add('hidden');
        submitButton.classList.remove('action-btn');
    }
}
