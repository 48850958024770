import { isInsideIframe } from '../utils';

const shouldWePretendToBeFromAnIframe = () => {
    const url = new URL(window.location);

    if (window.self === window.top) {
        const target = url.searchParams.get('target') || 'autowise';
        return ['iframe', 'embed'].includes(target);
    }

    return true;
};

if (isInsideIframe()) {
    import('../iframe-resize-helper');
    document.body.classList.remove('min-h-screen');

    const backButtonContainer = document.createElement('div');
    backButtonContainer.classList.add('lg:container', 'lg:mx-auto');

    const backButton = document.createElement('a');
    backButton.classList.add('cursor-pointer');
    backButton.innerHTML = `<a class="flex gap-1 cursor-pointer">
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
      <path stroke-linecap="round" stroke-linejoin="round" d="m18.75 4.5-7.5 7.5 7.5 7.5m-6-15L5.25 12l7.5 7.5" />
    </svg>
    <span>Terug naar de vorige pagina</span>
</a>`;
    backButton.onclick = () => window.history.back();
    backButtonContainer.appendChild(backButton);

    if (window.self.location.pathname !== '/voorraad/' && document.referrer.indexOf('/request') === -1) {
        // We don't want to show the back button when we are just done with our lease or purchase request.
        document.querySelector('main')?.insertAdjacentElement('beforebegin', backButtonContainer);
    }
}

if (shouldWePretendToBeFromAnIframe() || isInsideIframe()) {
    for (const element of document.querySelectorAll('body > footer')) {
        element.remove();
    }
}
